<template>
  <div class="timetable">
    <new-timetable />
  </div>
</template>

<script>
import { mapState } from "vuex";
import NewTimetable from "../components/timetable/newTimetable.vue";

export default {
  name: "Timetable",
  title: "SE-Timetable",

  props: ["filter"],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      lessons: (state) => state.lessons.lessons,
      currentSchoolYear: (state) => state.years.currentSchoolYear,
    }),
    tutorLessonStartDate: function () {
      return this.tutorLesson.start || new Date();
    },
  },
  components: {
    NewTimetable,
  },
  methods: {},
};
</script>
<style lang="scss" >
.timtable__head {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}
</style>
